import React from 'react';
import './Header.css';

function Header() {
  return (
    <header>
      <h1>Mark Vaz</h1>
      <p>Software Developer | Python | React | FastAPI | Django</p>
    </header>
  );
}

export default Header;
