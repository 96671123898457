import React from 'react';
import './Education.css';

function Education() {
  return (
    <section className="education">
      <h2>Education</h2>
      <div className="education-card">
        <h3>MIT School of Engineering, Pune</h3>
        <p>BTech, Computer Science and Engineering - Specialisation in Machine Learning</p>
      </div>
      <div className="education-card">
        <h3>The Orchid School, Pune</h3>
      </div>
      <div className="education-card">
        <h3>The Bishop’s Co-Ed School, Pune</h3>
      </div>
    </section>
  );
}

export default Education;
