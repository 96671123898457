import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LinearScale, CategoryScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import './Skills.css';

ChartJS.register(
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Skills() {
  const data = {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: 'Skills Progress',
        data: [65, 59, 80, 81, 56],
        fill: false,
        borderColor: '#bb86fc',
        tension: 0.1
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#e0e0e0',
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#e0e0e0'
        }
      },
      y: {
        ticks: {
          color: '#e0e0e0'
        }
      }
    }
  };

  return (
    <div className="skills">
      <h2>Technical Skills</h2>
      <div className="chart-container">
        <Line data={data} options={options} />
      </div>
    </div>
  );
}

export default Skills;
