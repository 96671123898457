import React from 'react';
import './Experience.css';

const experiences = [
  {
    title: 'Software Developer II',
    company: 'Vodafone Idea, Pune',
    date: '05/09/22 - Present',
    details: [
      'Developed a Centralized Middleware Portal using FastAPI and React with LDAP.',
      'Engineered components of a nationwide software upgrade platform.',
      'Developed a real-time telecom network monitoring portal.',
      'Designed a dynamic portal showcasing real-time data trends.'
    ]
  },
  {
    title: 'Python Developer Intern',
    company: 'Bonzai Digital Pvt Ltd, Pune',
    date: '01/21 - 07/21',
    details: [
      'Engineered Python automation frameworks to replace manual test cases.'
    ]
  }
];

function Experience() {
  return (
    <section className="experience">
      <h2>Experience</h2>
      <div className="timeline">
        {experiences.map((exp, index) => (
          <div className="timeline-item" key={index}>
            <h3>{exp.title}</h3>
            <h4>{exp.company}</h4>
            <p>{exp.date}</p>
            <ul>
              {exp.details.map((detail, i) => (
                <li key={i}>{detail}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Experience;
