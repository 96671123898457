import React from 'react';
import './Certifications.css';

function Certifications() {
  return (
    <section className="certifications">
      <h2>Certifications</h2>
      <div className="certification-card">
        <h3>Deep Learning, Stanford University, Coursera</h3>
      </div>
      <div className="certification-card">
        <h3>Introduction to Artificial Intelligence – Simplilearn</h3>
      </div>
      <div className="certification-card">
        <h3>Deep Learning with Keras and Tensorflow – Simplilearn</h3>
      </div>
      <div className="certification-card">
        <h3>Machine Learning using Python – Simplilearn</h3>
      </div>
      <div className="certification-card">
        <h3>AWS Associate – Technogeeks (Ongoing)</h3>
      </div>
    </section>
  );
}

export default Certifications;
