import React from 'react';
import './Intro.css';

function Intro() {
  return (
    <section className="intro">
      <div className="intro-content">
        <h1>Hi, I'm Mark Vaz</h1>
        <p>Welcome to my portfolio! I am a passionate Software Developer with expertise in Python, React, and more.</p>
        <p>Let's explore my journey and skills.</p>
      </div>
    </section>
  );
}

export default Intro;
